<template>
  <div class="v-licensing-privacy">
    <licensing-content title="隱私權政策" :content="mockContent" />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import LicensingContent from '@/components/Licensing/LicensingContent.vue';

const mockContent = [
  {
    title: 'WARRANTY',
    content: ['The Website and the information are provided on an "as is" and "as available" basis and Universal Production Music makes no warranties or representations, whether express or implied, in relation to the information or the Website, including but not limited to implied warranties or conditions of completeness, accuracy, satisfactory quality and fitness for a particular purpose.'],
  },
  {
    title: 'LIABILITY',
    content: [
      '(a) that it is technically inpossible to provide the Website free of faults and th at Universal Production Music does not undertake to do so;',
      '(b) that faults may lead to temporary unavaliablity of the Webiste...',
    ],
  },
];

export default defineComponent({
  name: 'LicensingPrivacy',
  components: {
    LicensingContent,
  },
  setup() {
    return {
      mockContent,
    };
  },

});
</script>
<style lang="scss" scoped>

</style>
